import React from 'react';
import PropTypes from 'prop-types';

import utils from 'app/helpers/utils';

class Linkify extends React.PureComponent {
  render() {
    const {str} = this.props;
    if (!str) return null;
    const parts = utils.extractUrlParts(str);
    return (
      <>
        {parts.map(({type, val}, i) => {
          if (type === 'url') {
            return <a href={val} target="_blank" key={i} className="blue-pink-hover" rel="nofollow">{val}</a>;
          }
          return <React.Fragment key={i}>{val}</React.Fragment>;
        })}
      </>
    );
  }
}

Linkify.propTypes = {
  str: PropTypes.string,
};

Linkify.defaultProps = {
  str: null,
};

class Paragraphs extends React.PureComponent {
  render() {
    const {str, pClassName, linkify} = this.props;
    if (!str) return null;
    return (<>
      {str.split('\n').map((p, i) => {
        if (!p) return null;
        return <p className={pClassName} key={i}>{linkify ? <Linkify str={p} /> : p}</p>;
      })}
    </>);
  }
}

Paragraphs.propTypes = {
  str: PropTypes.string,
  pClassName: PropTypes.string,
  linkify: PropTypes.bool,
};

Paragraphs.defaultProps = {
  str: null,
  pClassName: '',
  linkify: true,
};

export default {
  Linkify,
  Paragraphs,
};
