
import ActionsAx from 'app/actions/actions';
import CampaignsAx from 'app/actions/campaigns';
import VolEventsAx from 'app/actions/vol-events';

const Types = {
  LOAD: 'PAGE_CAMPAIGN_LOAD',
};

const Actions = {

  load: (campaignId) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CampaignsAx.get(campaignId)),
      dispatch(ActionsAx.fetch({campaignId})),
      dispatch(VolEventsAx.search({campaignId, includeEnded: true})),
    ]).then(([{campaign, company}, {actions}]) => ({campaign, company, actions}));

    return dispatch({ type: Types.LOAD, promise, campaignId });
  },
};

export {
  Types,
};

export default Actions;
