import { combineReducers } from 'redux';

import utils from 'app/reducers/utils';

import actions                    from 'app/reducers/entities/actions';
import bankAccounts               from 'app/reducers/entities/bank-accounts';
import campaigns                  from 'app/reducers/entities/campaigns';
import collections                from 'app/reducers/entities/collections';
import companies                  from 'app/reducers/entities/companies';
import companyCharges             from 'app/reducers/entities/company-charges';
import companyDashboardDatas      from 'app/reducers/entities/company-dashboard-datas';
import companyDashboards          from 'app/reducers/entities/company-dashboards';
import companyPurchases           from 'app/reducers/entities/company-purchases';
import countries                  from 'app/reducers/entities/countries';
import disbursements              from 'app/reducers/entities/disbursements';
import employeeDonations          from 'app/reducers/entities/employee-donations';
import employees                  from 'app/reducers/entities/employees';
import funds                      from 'app/reducers/entities/funds';
import gifts                      from 'app/reducers/entities/gifts';
import grants                     from 'app/reducers/entities/grants';
import groups                     from 'app/reducers/entities/groups';
import matchInquiries             from 'app/reducers/entities/match-inquiries';
import nonprofits                 from 'app/reducers/entities/nonprofits';
import recurringDonations         from 'app/reducers/entities/recurring-donations';
import slackChannels              from 'app/reducers/entities/slack-channels';
import slackConnectNonces         from 'app/reducers/entities/slack-connect-nonces';
import slackTokens                from 'app/reducers/entities/slack-tokens';
import tags                       from 'app/reducers/entities/tags';
import users                      from 'app/reducers/entities/users';
import volEventShifts             from 'app/reducers/entities/vol-event-shifts';
import volEvents                  from 'app/reducers/entities/vol-events';

const rootReducer = combineReducers({
  actions,
  balanceTransactions:        utils.createEntityReducer(['balanceTransaction', 'balanceTransactions']),
  balances:                   utils.createEntityReducer(['balance', 'balances']),
  bankAccounts,
  bracketGames:               utils.createEntityReducer(['bracketGame', 'bracketGames']),
  bracketLaunchDetails:       utils.createEntityReducer(['bracketLaunchDetail', 'bracketLaunchDetails']),
  bracketVoteRecords:         utils.createEntityReducer(['bracketVoteRecord', 'bracketVoteRecords']),
  brackets:                   utils.createEntityReducer(['bracket', 'brackets']),
  budgets:                    utils.createEntityReducer(['budget', 'budgets']),
  campaigns,
  cards:                      utils.createEntityReducer(['card', 'cards']),
  cattrSets:                  utils.createEntityReducer(['cattrSet', 'cattrSets']),
  cities:                     utils.createEntityReducer(['city', 'cities']),
  collections,
  companies,
  companyCharges,
  companyChargeErrors:        utils.createEntityReducer(['companyChargeError', 'companyChargeErrors']),
  companyDashboardDatas,
  companyDashboards,
  companyNonprofits:          utils.createEntityReducer(['companyNonprofit', 'companyNonprofits']),
  companyPurchases,
  countries,
  creditCards:                utils.createEntityReducer(['creditCard', 'creditCards']),
  customAttributes:           utils.createEntityReducer(['customAttribute', 'customAttributes']),
  customAttributeOptions:     utils.createEntityReducer(['customAttributeOption', 'customAttributeOptions']),
  disbursements,
  driveDonations:             utils.createEntityReducer(['driveDonation', 'driveDonations']),
  driveDonationEntries:       utils.createEntityReducer(['driveDonationEntry', 'driveDonationEntries']),
  driveGoods:                 utils.createEntityReducer(['driveGood', 'driveGoods']),
  employeeDonations,
  employees,
  funds,
  fxRatesSets:                utils.createEntityReducer(['fxRatesSet', 'fxRatesSets']),
  gifts,
  grants,
  groupEmployees:             utils.createEntityReducer(['groupEmployee', 'groupEmployees']),
  groupTypes:                 utils.createEntityReducer(['groupType', 'groupTypes']),
  groups,
  happenings:                 utils.createEntityReducer(['happening', 'happenings']),
  irsEntities:                utils.createEntityReducer(['irsEntity', 'irsEntities']),
  matchInquiries,
  nonprofitProfiles:          utils.createEntityReducer(['nonprofitProfile', 'nonprofitProfiles']),
  nonprofits,
  nteeCodes:                  utils.createEntityReducer(['nteeCode', 'nteeCodes']),
  offlineDonations:           utils.createEntityReducer(['offlineDonation', 'offlineDonations']),
  payrollDeductions:          utils.createEntityReducer(['payrollDeduction', 'payrollDeductions']),
  recurringDonations,
  recurringPayrollDeductions: utils.createEntityReducer(['recurringPayrollDeduction', 'recurringPayrollDeductions']),
  slackChannels,
  slackConnectNonces,
  slackTokens,
  socialPosts:                utils.createEntityReducer(['socialPost', 'socialPosts']),
  socialPostListings:         utils.createEntityReducer(['socialPostListing', 'socialPostListings']),
  socialReactionSets:         utils.createEntityReducer(['socialReactionSet', 'socialReactionSets']),
  tags,
  users,
  volEventParticipants:       utils.createEntityReducer(['volEventParticipant', 'volEventParticipants']),
  volEvents,
  volEventShifts,
  volTimeEntries:             utils.createEntityReducer(['volTimeEntry', 'volTimeEntries']),
  walletLoads:                utils.createEntityReducer(['walletLoad', 'walletLoads']),
});

export default rootReducer;
