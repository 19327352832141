import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DndProvider, DropTarget, DragSource, DragPreviewImage } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import EllipsisMenu from 'app/components/common/ellipsis-menu';
import FancyImage   from 'app/components/common/fancy-image';
import Icon         from 'app/components/common/icon';
import Modal        from 'app/components/common/modal';
import Duck         from 'app/ducks/modal-social-post';
import cdn          from 'app/helpers/cdn';



/*
 *  Slot
 */

class ImgSlot extends React.PureComponent {
  onDrop(item) {
    this.props.onDrop({fromIndex: item.index, toIndex: this.props.index});
  }
  render() {
    const {connectDnd, dndIsOver, dndIsDragging} = this.props;
    const hoverClassName = dndIsOver ? 'dnd-hover' : '';
    const dndClassName = dndIsDragging ? 'dnd-dragging' : '';
    const contents = (
      <div className={`modal-simgs-list-slot ${hoverClassName} ${dndClassName}`}>
        {this.props.children}
      </div>
    );
    return connectDnd ? connectDnd(contents) : contents;
  }
}
ImgSlot.propTypes = {
  index: PropTypes.number.isRequired,
  onDrop: PropTypes.func.isRequired,
};
const dndDropSpec = {
  drop: (props, monitor, component) => {
    component.onDrop(monitor.getItem());
  },
  // hover: (props, monitor, component) => {},
  // canDrop: (props, monitor) => {},
};
const dndDropToProps = (connect, monitor, props) => ({
  connectDnd: connect.dropTarget(),
  dndIsOver: monitor.isOver(),
  dndIsDragging: monitor.canDrop(),
});
ImgSlot.Dnd = DropTarget('post-img', dndDropSpec, dndDropToProps)(ImgSlot);



/*
 *  Card
 */

class ImgCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onClickMoveUp = this.onClickMoveUp.bind(this);
    this.onClickMoveDown = this.onClickMoveDown.bind(this);
  }
  onClickDelete(event) {
    event.preventDefault();
    this.props.onDelete(this.props.index);
  }
  onClickMoveUp(event) {
    event.preventDefault();
    this.props.onMoveUp(this.props.index);
  }
  onClickMoveDown(event) {
    event.preventDefault();
    this.props.onMoveDown(this.props.index);
  }
  render() {
    const {connectDragSource, connectDragPreview, imgPath, isLast, index} = this.props;
    const isFirst = index === 0;
    const contents = (
      <div className={`modal-simgs-list-slot-card`}>
        <DragPreviewImage src={cdn.imgUrl(imgPath, {width: 200, height: 200})} connect={connectDragPreview} />
        <FancyImage imgPath={imgPath} width={392} height={392} aspectRatio="1" blur={10} className="modal-simgs-list-slot-card-img" />
        <div className="modal-simgs-list-slot-card-menu">
          <EllipsisMenu left>
            {!isFirst && (
              <a href="#" onClick={this.onClickMoveUp}><Icon.Caret direction="left" /> Move Left</a>
            )}
            {!isLast && (
              <a href="#" onClick={this.onClickMoveDown}><Icon.Caret direction="right" /> Move Right</a>
            )}
            <a href="#" onClick={this.onClickDelete}><Icon.Bin /> Delete</a>
          </EllipsisMenu>
        </div>
      </div>
    );
    return connectDragSource ? connectDragSource(contents) : contents;
  }
}
ImgCard.propTypes = {
  index: PropTypes.number.isRequired,
  imgPath: PropTypes.string.isRequired,
  isLast: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onMoveUp: PropTypes.func.isRequired,
  onMoveDown: PropTypes.func.isRequired,
};
const dndDragSpec = {
  beginDrag: (props, monitor, component) => {
    return {index: props.index};
  },
  endDrag: (props, monitor, component) => {},
  // canDrag: (props, monitor) => {},
};
const dndDragToProps = (connect, monitor, props) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
});
ImgCard.Dnd = DragSource('post-img', dndDragSpec, dndDragToProps)(ImgCard);



/*
 *  Modal
 */

class ModalSocialPostImages extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.onDropImg = this.onDropImg.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onMoveUp = this.onMoveUp.bind(this);
    this.onMoveDown = this.onMoveDown.bind(this);
  }

  onClose() {
    this.props.onClose();
  }

  onDropImg({fromIndex, toIndex}) {
    this.props.imgSwap(fromIndex, toIndex);
  }

  onDelete(index) {
    this.props.imgDelete(index);
  }

  onMoveUp(index) {
    this.props.imgMoveUp(index);
  }

  onMoveDown(index) {
    this.props.imgMoveDown(index);
  }

  render() {
    const {imgPaths} = this.props;
    if (!imgPaths?.length) return null;
    return (
      <Modal onClose={this.onClose} className="modal-simgs">
        <DndProvider backend={HTML5Backend}>
          <div className="modal-simgs-head">
            <div className="modal-simgs-head-text">
              <h1 className="modal-simgs-head-title">Manage Photos</h1>
              <div className="modal-simgs-head-dir">Drag and drop to rearrange the order.</div>
            </div>
            <button className="btn small" onClick={this.onClose}>Done</button>
          </div>
          <div className="modal-simgs-body">
            <div className="modal-simgs-list">
              {imgPaths.map((imgPath, index) => {
                const isLast = index === (imgPaths.length - 1);
                return (
                  <ImgSlot.Dnd index={index} key={index} onDrop={this.onDropImg}>
                    <ImgCard.Dnd isLast={isLast} index={index} imgPath={imgPath} onDelete={this.onDelete} onMoveUp={this.onMoveUp} onMoveDown={this.onMoveDown} />
                  </ImgSlot.Dnd>
                );
              })}
            </div>
          </div>
          <div className="modal-simgs-foot"></div>
        </DndProvider>
      </Modal>
    );
  }

}

ModalSocialPostImages.propTypes = {
  onClose: PropTypes.func.isRequired,
};

ModalSocialPostImages.defaultProps = {
};

const stateToProps = (state) => ({
  imgPaths: Duck.Slx.imgPaths(state),
});

const dispatchToProps = (dispatch) => ({
  imgSwap: (fromIndex, toIndex) => dispatch(Duck.Ax.imgSwap(fromIndex, toIndex)),
  imgDelete: (index) => dispatch(Duck.Ax.imgDelete(index)),
  imgMoveUp: (index) => dispatch(Duck.Ax.imgMoveUp(index)),
  imgMoveDown: (index) => dispatch(Duck.Ax.imgMoveDown(index)),
});

export default connect(stateToProps, dispatchToProps)(ModalSocialPostImages);
