import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Link          from 'app/components/common/link';
import {
  USER_DEFAULT_AVATAR_URL,
}                    from 'app/constants';
import cdn           from 'app/helpers/cdn';
import paths         from 'app/paths';
import AuthSlx       from 'app/selectors/auth';

const noop = () => {};

class PostCardPrompt extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickPrompt = this.onClickPrompt.bind(this);
  }

  onClickPrompt() {
    this.props.onWantsCreate();
  }

  render() {
    const {currentUser: user, employee} = this.props;
    const prompStr = `What's on your mind, ${employee.firstName}?`;
    const avatarUrl = user.avatarUrl
      ? cdn.imgUrl(user.avatarUrl, {width: 60})
      : USER_DEFAULT_AVATAR_URL;
    return (<>
      <button className="spost-card-fake-input" onClick={this.onClickPrompt}>{prompStr}</button>
      <div className="spost-card-foot">
        <img className="spost-card-foot-img" src={avatarUrl} />
        <div className="spost-card-foot-det">
          <div className="spost-card-foot-det-name">
            <Link href={paths.user(user.id)} className="pink-hover">{`${employee.firstName} ${employee.lastName}`}</Link>
          </div>
          <div className="spost-card-foot-det-date"></div>
        </div>
      </div>
    </>);
  }

}

PostCardPrompt.propTypes = {
  onWantsCreate: PropTypes.func,
};

PostCardPrompt.defaultProps = {
  onWantsCreate: noop,
};

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
  employee: AuthSlx.currentUser(state)?.employment?.employee,
});

export default connect(stateToProps)(PostCardPrompt);
