import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import PageAx             from 'app/actions/company-admin/page-matches';
import DateRangePicker    from 'app/components/common/date-range-picker';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import Pagination         from 'app/components/common/pagination';
import EntityInput        from 'app/components/company-admin/common/entity-input';
import CadminLayout       from 'app/components/company-admin/layout/';
import NewOffDonModal     from 'app/components/company-admin/matches/modal-off-dons-new';
import TopLineStats       from 'app/components/company-admin/top-line-stats';
import PageLoading        from 'app/components/layout/page-loading';
import {
  EmployeeDonationMatchStatuses as MatchStatuses,
}                         from 'app/constants';
import history            from 'app/history';
import paths              from 'app/paths';
import CadminSlx          from 'app/selectors/company-admin/';
import PageSlx            from 'app/selectors/company-admin/page-matches';

const { PENDING, APPROVED, DECLINED, EMPLOYEE_LIMIT_REACHED, COMPANY_LIMIT_REACHED } = MatchStatuses;

class PageCadminMatches extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showOffDonModal: false,
    };

    this.onSelectDateRange = this.onSelectDateRange.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.onChangeSelectedCampaign = this.onChangeSelectedCampaign.bind(this);
    this.onChangeSelectedEmployee = this.onChangeSelectedEmployee.bind(this);
    this.onChangeSelectedNonprofit = this.onChangeSelectedNonprofit.bind(this);
    this.onClickNewOffDon = this.onClickNewOffDon.bind(this);
    this.onCloseOffDonModal = this.onCloseOffDonModal.bind(this);
  }

  onClickNewOffDon() {
    this.setState({showOffDonModal: true});
  }

  onCloseOffDonModal(shouldRefresh=false) {
    this.setState({showOffDonModal: false});
    if (!shouldRefresh) return;
    if (this.props.offDonsActive) {
      history.millieRefresh();
    } else {
      history.push(paths.cadminOfflineDonations(this.props.company.slug));
    }
  }

  onClickNonprofit(nonprofitId, event) {
    event.preventDefault();
    this.props.openNonprofitModal(nonprofitId);
  }

  onSelectDateRange({startDateStr, endDateStr}) {
    this.props.setSearchParams({
      startDate: startDateStr,
      endDate: endDateStr,
      page: 1,
    });
  }

  onChangeSelectedCampaign(campaign) {
    this.props.setSearchParams({campaignId: campaign?.id, page: 1});
  }

  onChangeSelectedEmployee(employee) {
    this.props.setSearchParams({employeeId: employee?.id, page: 1});
  }

  onChangeSelectedNonprofit(nonprofit) {
    this.props.setSearchParams({nonprofitId: nonprofit?.id, page: 1});
  }

  onSelectPage(page) {
    this.props.setSearchParams({page});
  }

  renderSummary() {
    const { summary } = this.props;
    const stats = [
      {icon: <Icon.PaginateFilterHeart />,  key: 'Matches',                valType: 'int',   val: summary?.numMatches},
      {icon: <Icon.LoveHeartHold />,        key: 'Nonprofits Helped',      valType: 'int',   val: summary?.numNonprofits},
      {icon: <Icon.MonetizationTablet />,   key: 'Matched Dollars',        valType: 'cents', val: summary?.sumMatchedAmountInCents},
      {icon: <Icon.UserMultipleNeutral2 />, key: 'Employees Participated', valType: 'int',   val: summary?.numEmployees},
    ];
    return <TopLineStats stats={stats} color="purple" />;
  }

  renderFilters() {
    const {currentFiscalYear, company, searchParams} = this.props;
    const {matchStatus, startDate, endDate, campaignId, employeeId, nonprofitId} = searchParams;
    return (
      <div className="ca-main-filters">
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Date Range</label>
          <DateRangePicker leftAlign onSelect={this.onSelectDateRange} currentFiscalYear={currentFiscalYear} startDateStr={startDate} endDateStr={endDate} allowClear label="All Dates" />
        </div>
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Campaign</label>
          <EntityInput.Campaign campaignId={campaignId} onChange={this.onChangeSelectedCampaign} />
        </div>
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Employee</label>
          <EntityInput.Employee employeeId={employeeId} onChange={this.onChangeSelectedEmployee} />
        </div>
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Nonprofit</label>
          <EntityInput.Nonprofit nonprofitId={nonprofitId} onChange={this.onChangeSelectedNonprofit} intl={!!company?.features?.international} />
        </div>
      </div>
    );
  }

  renderBox() {
    const {searchParams, company, currentFiscalYear, pagination, children, inquiriesActive, offDonsActive, attnNums, tableTitle} = this.props;
    const {matchStatus, startDate, endDate} = searchParams;
    const matchesActive = !inquiriesActive && !offDonsActive;

    return (<>
      {this.renderSummary()}
      <div className="ca-box">
        <div className="ca-box-header">
          <h1 className="ca-box-header-title">{tableTitle}</h1>
          <div className="ca-box-header-controls">
            <a href={paths.cadminMatchesCsv(company.slug, searchParams)} className="btn secondary icon small"><Icon.CommonFileTextDownload /> CSV</a>
          </div>
        </div>
        <div className="ca-box-tabs">
          <div className="ca-box-tabs-tabs">
            <Link className={`ca-box-tabs-tab ${(matchesActive && !matchStatus) ? 'active' : ''}`} href={paths.cadminMatches(company.slug, {...searchParams, page: 1, matchStatus: null})}>All</Link>
            <Link className={`ca-box-tabs-tab ${(matchesActive && matchStatus === PENDING) ? 'active' : ''}`} href={paths.cadminMatches(company.slug, {...searchParams, page: 1, matchStatus: PENDING})}>
              Pending
              {!!attnNums.pendingMatches && (
                <span className="ca-box-tabs-tab-attn">{attnNums.pendingMatches}</span>
              )}
            </Link>
            <Link className={`ca-box-tabs-tab ${(matchesActive && matchStatus === APPROVED) ? 'active' : ''}`} href={paths.cadminMatches(company.slug, {...searchParams, page: 1, matchStatus: APPROVED})}>Approved</Link>
            <Link className={`ca-box-tabs-tab ${(matchesActive && matchStatus === DECLINED) ? 'active' : ''}`} href={paths.cadminMatches(company.slug, {...searchParams, page: 1, matchStatus: DECLINED})}>Restricted</Link>
            <Link className={`ca-box-tabs-tab separate ${offDonsActive ? 'active' : ''}`} href={paths.cadminOfflineDonations(company.slug, {...searchParams, page: 1, matchStatus: null})}>
              Offline Donations
              {!!attnNums.pendingOfflineDonations && (
                <span className="ca-box-tabs-tab-attn">{attnNums.pendingOfflineDonations}</span>
              )}
            </Link>
            <Link className={`ca-box-tabs-tab separate ${inquiriesActive ? 'active' : ''}`} href={paths.cadminMatchInquiries(company.slug, {...searchParams, page: 1, matchStatus: null})}>
              Inquiries
              {!!attnNums.pendingMatchInquiries && (
                <span className="ca-box-tabs-tab-attn">{attnNums.pendingMatchInquiries}</span>
              )}
            </Link>
          </div>
        </div>
        <div className="ca-box-body">
          {children}
          <Pagination pagination={pagination} onSelectPage={this.onSelectPage} />
        </div>
      </div>
    </>);
  }

  render() {
    const {company, tableTitle, searchParams} = this.props;
    const {employeeId, nonprofitId} = searchParams;
    const {showOffDonModal} = this.state;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="page-cadmin-matches" company={company} activeItem="matches">
        <Meta title={`${tableTitle} | Millie`} />
        <div className="ca-main-head">
          <h1 className="ca-main-head-h1">Donations</h1>
          <div className="ca-main-head-actions">
            <button className="btn special purple" onClick={this.onClickNewOffDon}>Track Offline Donation</button>
          </div>
        </div>
        {this.renderFilters()}
        {this.renderBox()}
        {showOffDonModal && <NewOffDonModal onClose={this.onCloseOffDonModal} defaultEmployeeId={employeeId} defaultNonprofitId={nonprofitId} />}
      </CadminLayout>
    );
  }

}

PageCadminMatches.propTypes = {
  children: PropTypes.node,
  searchParams: PropTypes.object.isRequired,
  pagination: PropTypes.object,
  setSearchParams: PropTypes.func.isRequired,
  inquiriesActive: PropTypes.bool.isRequired,
  offDonsActive: PropTypes.bool.isRequired,
  tableTitle: PropTypes.string,
};

PageCadminMatches.defaultProps = {
  children: null,
  inquiriesActive: false,
  offDonsActive: false,
  tableTitle: "Donations",
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  attnNums: CadminSlx.attnNums(state),
  currentFiscalYear: CadminSlx.currentFiscalYear(state),

  summary: PageSlx.summary(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageCadminMatches);
