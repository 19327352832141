import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import PageAx             from 'app/actions/page-vol-event';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import ErrorPage          from 'app/components/errors/page';
import MainLayout         from 'app/components/layout/main-layout';
import PageLoading        from 'app/components/layout/page-loading';
import VolEventProfile    from 'app/components/volunteer/vol-event-profile';
import config             from 'app/config';
import history            from 'app/history';
import paths              from 'app/paths';
import PageSlx            from 'app/selectors/page-vol-event';

class PageVolEvent extends React.PureComponent {

  render() {
    const { loadPending, volEvent, myParticipants } = this.props;
    if (loadPending) return <PageLoading />;
    if (!loadPending && !volEvent) return <ErrorPage statusCode={404} />;

    return (
      <MainLayout>
        <Meta
          title={`${volEvent.title} | Millie`}
          ogDesc={volEvent.description}
          ogImgUrl={volEvent.imgPath && `${config.s3BaseUrl}/${volEvent.imgPath}`}
        />
        <VolEventProfile volEvent={volEvent} myParticipants={myParticipants} />
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  loadPending: PageSlx.loadPending(state),
  volEvent: PageSlx.volEvent(state),
  myParticipants: PageSlx.myParticipants(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageVolEvent);
