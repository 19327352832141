import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import PostCard      from 'app/components/social/post-card';
import {
  SOCIAL_PROMPT_POST_ID,
  SocialFeedTypes as FeedTypes,
}                    from 'app/constants';
import ModalPostDuck from 'app/ducks/modal-social-post';

const CARD_WIDTH = 320;
const CARD_GUTTER = 24;

class SocialMasonry extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      heightMap: {},
      colCount: 1,
    };
    this.mounted = true;
    // new heights are stored here immediately; values are then moved to state via debounced function; this is to avoid many state updates in quick succession
    this.heightMap = {};

    this.refColsCon = React.createRef();

    this.onWantsCreate = this.onWantsCreate.bind(this);
    this.onCalcHeight = this.onCalcHeight.bind(this);
    this.updateHeightMap = _.debounce(this.updateHeightMap.bind(this), 50);
    this.onWindowResizeThrottled = _.throttle(this.onWindowResize.bind(this), 500, {leading: false});
  }

  componentDidMount() {
    this.mounted = true;
    window.addEventListener('resize', this.onWindowResizeThrottled);
    this.onWindowResize();
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.onWindowResizeThrottled);
  }

  componentDidUpdate(prevProps) {
    this.onWindowResize();
  }

  get cols() {
    const {heightMap, colCount} = this.state;
    const {listingIds: ids} = this;
    if (!ids) return null;
    // gather the ids that are ready
    const readyIds = [];
    let i = 0;
    while (heightMap[ids[i]]) {
      readyIds.push(ids[i]);
      i++;
    }
    // construct cols & helpers
    const cols = _.times(colCount).map(() => []);
    const getColHeight = (col) => {
      const cardHeight = _.sum(col.map(id => heightMap[id]));
      const marginHeight = col.length * CARD_GUTTER;
      return cardHeight + marginHeight;
    };
    const getShortestCol = () => {
      const colHeights = cols.map(getColHeight);
      const shortestHeight = Math.min(...colHeights);
      const shortestIndex = colHeights.indexOf(shortestHeight);
      return cols[shortestIndex];
    };
    // add each id to the shortest col
    readyIds.forEach((id) => {
      const col = getShortestCol();
      col.push(id);
    });
    //
    return cols;
  }

  get allowNew() {
    return !!this.props.feedType;
  }

  get listingIds() {
    const {listingIds} = this.props;
    if (!listingIds) return null;
    if (!this.allowNew) return listingIds;
    return [SOCIAL_PROMPT_POST_ID, ...listingIds];
  }

  onWindowResize() {
    if (!this.mounted) return;
    const conEl = this.refColsCon.current;
    if (!conEl) return;
    const width = conEl.clientWidth;
    const colCount = (() => {
      if (width >= ((CARD_WIDTH * 4) + (CARD_GUTTER * 3))) return 4;
      if (width >= ((CARD_WIDTH * 3) + (CARD_GUTTER * 2))) return 3;
      if (width >= ((CARD_WIDTH * 2) + (CARD_GUTTER * 1))) return 2;
      return 1;
    })();
    this.setState({colCount});
  }

  onWantsCreate() {
    const {feedType, feedId, createPost} = this.props;
    createPost({feedType, feedId});
  }

  updateHeightMap() {
    if (!this.mounted) return;
    this.setState((prevState) => {
      return {heightMap: {...this.heightMap}};
    });
  }

  onCalcHeight(postId, height) {
    this.heightMap[postId] = height;
    this.updateHeightMap();
  }

  render() {
    const {listingIds} = this;
    if (!listingIds?.length) return null;
    const cols = this.cols;
    // console.log('-- heightMap', this.state.heightMap);

    return (
      <div className="smasonry">
        {/* <pre>{JSON.stringify({heightMap: this.state.heightMap, cols: this.cols}, null, 4)}</pre> */}
        <div className="smasonry-hidden">
          {listingIds.map((id) => {
            return <PostCard key={id} id={id} onCalcHeight={this.onCalcHeight} />;
          })}
        </div>
        <div className="smasonry-cols" ref={this.refColsCon}>
          {cols.map((ids, i) => {
            return (
              <div className="smasonry-cols-col" key={i}>
                {ids.map((id) => {
                  return <PostCard key={id} id={id} className="smasonry-cols-col-card" onWantsCreate={this.onWantsCreate} />;
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

}

SocialMasonry.propTypes = {
  feedType: PropTypes.oneOf(Object.values(FeedTypes)),
  feedId: PropTypes.string,
  listingIds: PropTypes.arrayOf(PropTypes.string),
};

SocialMasonry.defaultProps = {
  listingIds: null,
};

// const stateToProps = (state) => ({});

const dispatchToProps = (dispatch) => ({
  createPost: (params) => dispatch(ModalPostDuck.Ax.open(params)),
});

export default connect(undefined, dispatchToProps)(SocialMasonry);
