import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import Icon          from 'app/components/common/icon';
import Link          from 'app/components/common/link';
import Text          from 'app/components/common/text';
import Prompt        from 'app/components/social/post-card-prompt';
import ReactionSet   from 'app/components/social/reaction-set';
import UrlPreview    from 'app/components/social/url-preview';
import {
  USER_DEFAULT_AVATAR_URL,
  SOCIAL_PROMPT_POST_ID,
}                    from 'app/constants';
import SocialDuck    from 'app/ducks/social';
import cdn           from 'app/helpers/cdn';
import paths         from 'app/paths';

const LARGE_TEXT_CUTOFF = 200;
const noop = () => {};

class PostCard extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refCard = React.createRef();
    this.reportedHeight = null;

    this.onLoadImg = this.onLoadImg.bind(this);
    this.onErrorImg = this.onErrorImg.bind(this);
  }

  componentDidMount() {
    this.calcHeight();
  }

  componentDidUpdate(prevProps) {
    this.calcHeight();
  }

  get largeText() {
    const {post} = this;
    if (post.imgPaths?.length) return false;
    if (post.previewUrlObj) return false;
    return (post.body || '').length < LARGE_TEXT_CUTOFF;
  }

  get isPrompt() {
    return this.props.id === SOCIAL_PROMPT_POST_ID;
  }

  get post() {
    return this.props.listing?.post || null;
  }
  get employee() {
    return this.post?.employee || null;
  }
  get user() {
    return this.employee?.user || null;
  }

  calcHeight() {
    if (!this.props.onCalcHeight) return;
    const height = this.refCard.current.clientHeight;
    if (height !== this.reportedHeight) {
      this.reportedHeight = height;
      this.props.onCalcHeight(this.props.id, height);
    }
  }

  onLoadImg() {
    this.calcHeight();
  }

  onErrorImg() {
    this.calcHeight();
  }

  renderFoot({employee, dateStr, user, commentCount=0}) {
    const avatarUrl = user.avatarUrl
      ? cdn.imgUrl(user.avatarUrl, {width: 60})
      : USER_DEFAULT_AVATAR_URL;
    return (
      <div className="spost-card-foot">
        <img className="spost-card-foot-img" src={avatarUrl} />
        <div className="spost-card-foot-det">
          <div className="spost-card-foot-det-name">
            <Link href={paths.user(user.id)} className="pink-hover">{`${employee.firstName} ${employee.lastName}`}</Link>
          </div>
          <div className="spost-card-foot-det-date">{dateStr || ''}</div>
        </div>
        {!!commentCount && (
          <div className="spost-card-foot-com">
            {commentCount} <Icon.MessageBubbleEmpty />
          </div>
        )}
      </div>
    );
  }

  renderPrompt() {
    const {className} = this.props;
    return (
      <div className={`spost-card ${className}`} ref={this.refCard}>
        <Prompt onWantsCreate={this.props.onWantsCreate} />
      </div>
    );
  }

  renderReactions() {
    const {onCalcHeight} = this.props;
    const {post} = this;
    if (onCalcHeight) return null;
    if (this.isPrompt) return null;
    if (!post?.id) return null;
    return <ReactionSet id={post.id} />;
  }

  render() {
    const {className} = this.props;
    const {post} = this;
    if (this.isPrompt) return this.renderPrompt();
    if (!post) return null;
    const {employee, user} = post;
    const imgPath = (post.imgPaths || [])[0];
    const hasBody = !!(post.body || '').trim();
    const urlObj = (() => {
      if (imgPath) return null;
      return post.previewUrlObj || null;
    })();
    const dateStr = (() => {
      const sameYear = post.dateTimeStr.startsWith(`${moment().year()}`);
      const format = sameYear ? 'MMM Do' : 'MMM Do, YYYY';
      return moment.utc(post.dateTimeStr).format(format);
    })();
    // const commentCount = 42;
    // const commentCount = Math.floor(Math.random() * 7);

    return (
      <div className={`spost-card ${className}`} ref={this.refCard}>
        {imgPath && (
          <img src={cdn.imgUrl(imgPath, {width: 592, height: 1184})} className="spost-card-img" onLoad={this.onLoadImg} />
          // <img src={cdn.imgUrl(imgPath, {width: 762, height: 1184})} className="spost-card-img" onLoad={this.onLoadImg} onError={this.onErrorImg} ref={this.refImg} />
        )}
        {urlObj && (
          <UrlPreview urlObj={urlObj} />
        )}
        {hasBody && (
          <div className={`spost-card-body ${this.largeText ? 'large' : 'small'}`}>
            <Text.Paragraphs str={post.body} pClassName="spost-card-body-p" />
          </div>
        )}
        {this.renderFoot({employee, user, dateStr, commentCount: post.commentCount})}
        {this.renderReactions()}
      </div>
    );
  }

}

PostCard.propTypes = {
  id: PropTypes.string.isRequired,
  onCalcHeight: PropTypes.func,
  className: PropTypes.string,
  onWantsCreate: PropTypes.func,
};

PostCard.defaultProps = {
  onCalcHeight: null,
  className: '',
  onWantsCreate: noop,
};

const stateToProps = (_state, ownProps) => {
  const listingSlx = SocialDuck.Slx.listingSlx(ownProps.id);
  return (state) => ({
    listing: listingSlx(state),
  });
};

export default connect(stateToProps)(PostCard);
