import axios from 'app/axios';

import config from 'app/config';

const client = axios.create({
  baseURL: '/api',
});

const urlsMetaCache = {};

const Endpoints =  {

  /*
   *  Auth
   */

  authRegister: (email, password, firstName, lastName, postalCode, {nonprofitId, employeeInviteToken} = {}) => {
    const body = {firstName, lastName, email, password, postalCode, deviceType: 'browser', nonprofitId, employeeInviteToken};
    return client.post('/auth/register', body).then(res => res.data);
  },

  authLogin: ({email, password}) => {
    const body = {email, password};
    return client.post('/auth/login', body).then(res => res.data);
  },

  authSsoLogin: (email) => {
    const params = {email};
    return client.get('/auth/sso-login', {params}).then(res => res.data);
  },

  authLogout: () => {
    return client.post('/auth/logout').then(res => res.data);
  },

  authWhoami: () => {
    return client.get('/auth/whoami').then(res => res.data);
  },

  authStopMasquerading: () => {
    return client.patch('/auth/stop-masquerading').then(res => res.data);
  },

  authResetPassword: (email) => {
    const body = {email};
    return client.post('/auth/reset-password', body).then(res => res.data);
  },

  authEmployeeEmailSignup: (email) => {
    const body = {email};
    return client.post('/auth/employee-email-signup', body).then(res => res.data);
  },

  authMfaGetStatus: () => {
    return client.get('/auth/2fa').then(res => res.data);
  },

  authMfaSetPhone: (phoneE164) => {
    const body = {phoneE164};
    return client.post('/auth/2fa/phone', body).then(res => res.data);
  },

  authMfaResend: () => {
    return client.put('/auth/2fa/resend').then(res => res.data);
  },

  authMfaSubmitCode: (code) => {
    const body = {code};
    return client.post('/auth/2fa', body).then(res => res.data);
  },

  /*
   *  Email Verifications
   */

  emailVerificationsVerify: (id) => {
    return client.post(`/email-verifications/${id}/verify`).then(res => res.data);
  },

  emailVerificationsResendId: (id) => {
    return client.post(`/email-verifications/${id}/resend`).then(res => res.data);
  },

  emailVerificationsResend: () => {
    return client.post('/email-verifications/resend').then(res => res.data);
  },

  /*
   *  Cities
   */

  citiesSearch: (search) => {
    const params = {search};
    return client.get('/cities', {params}).then(res => res.data);
  },

  /*
   *  Nonprofits
   */

  /** This is the nonprofit search for users or employees. Unlike other
   *  searches, it honors the company `searchApprovedNonprofitsOnly` flag. */
  nonprofitsSearch: ({category, nteeCode, search, page, pageSize, postalCode, distance, sort, badges, country, companyId, forceIncludeUnapproved, inFavorites=false, countryCode, cityId} = {}) => {
    const params = {category, nteeCode, search, page, pageSize, postalCode, distance, sort, badges, countries: country, companyId, forceIncludeUnapproved, inFavorites, countryCode, cityId};
    return client.get('/nonprofits', {params}).then(res => res.data);
  },

  nonprofitsGet: (idOrEin) => {
    return client.get(`/nonprofits/${idOrEin}`).then(res => res.data);
  },

  nonprofitsClaim: (id) => {
    return client.post(`/nonprofits/${id}/claim`).then(res => res.data);
  },

  nonprofitsFavorite: (id) => {
    return client.post(`/nonprofits/${id}/favorite`).then(res => res.data);
  },

  nonprofitsUnfavorite: (id) => {
    return client.delete(`/nonprofits/${id}/favorite`).then(res => res.data);
  },

  nonprofitsGetFavoriteIds: () => {
    return client.get(`/nonprofits/favorite-ids`).then(res => res.data);
  },

  /*
   *  IRS Entities
   */

  irsEntitesGet: (ein) => {
    return client.get(`/irs-entities/${ein}`).then(res => res.data);
  },

  /*
   *  NonprofitProfiles
   */

  nonprofitProfilesGet: (id) => {
    return client.get(`/nonprofit-profiles/${id}`).then(res => res.data);
  },

  nonprofitProfilesSave: (id, fields) => {
    const body = { fields };
    return client.patch(`/nonprofit-profiles/${id}`, body).then(res => res.data);
  },

  /*
   *  Ntee Codes
   */

  nteeCodesSearch: (params={}) => {
    return client.get(`/ntee-codes`, {params}).then(res => res.data);
  },

  nteeCodesGet: (code) => {
    return client.get(`/ntee-codes/${code}`).then(res => res.data);
  },

  /*
   *  Images
   */

  imagesCreatePresignedUrl: (file) => {
    const body = {fileName: file.name, fileType: file.type};
    return client.post('/images/presigned-url', body).then(res => res.data);
  },

  /*
   *  Credit Cards
   */

  creditCardsCreate: (stripeToken, attrs={}) => {
    const body = {...attrs, stripeToken};
    return client.post('/credit-cards', body).then(res => res.data);
  },

  creditCardsFetch: () => {
    return client.get('/credit-cards').then(res => res.data);
  },

  creditCardsDelete: (id) => {
    return client.delete(`/credit-cards/${id}`).then(res => res.data);
  },

  creditCardsMakeDefault: (id) => {
    return client.post(`/credit-cards/${id}/make-default`).then(res => res.data);
  },

  creditCardsUpdate: (id, attrs) => {
    const body = attrs;
    return client.patch(`/credit-cards/${id}`, body).then(res => res.data);
  },

  /*
   *  Wallet
   */

  walletLoad: (creditCardId, amount, currencyCode) => {
    const body = {creditCardId, amount, currencyCode};
    return client.post('/wallet/load', body).then(res => res.data);
  },

  walletLoadsFetch: () => {
    return client.get('/wallet/loads').then(res => res.data);
  },

  /*
   *  Donations
   */

  donationsCreate: ({donatableType, donatableId, amount, currencyCode, anonymous=false, campaignId=null, balanceType, note=null, feesCovered=false, walletLoadId=null}) => {
    const body = {donatableType, donatableId, amount, currencyCode, anonymous, campaignId, balanceType, note, feesCovered, walletLoadId};
    return client.post('/donations', body).then(res => res.data);
  },

  /*
   *  Recurring Donations
   */

  recDonationsFetch: () => {
    return client.get('/recurring-donations').then(res => res.data);
  },

  recDonationsCreate: ({nonprofitId, amount, currencyCode, creditCardId, balanceType, anonymous=false, campaignId=null, note=null, feesCovered=false}) => {
    const body = {nonprofitId, amount, currencyCode, creditCardId, balanceType, anonymous, campaignId, note, feesCovered};
    return client.post('/recurring-donations', body).then(res => res.data);
  },

  recDonationsCancel: (id) => {
    return client.post(`/recurring-donations/${id}/cancel`).then(res => res.data);
  },

  // recDonationsResume: (id) => {
  //   return client.post(`/recurring-donations/${id}/resume`).then(res => res.data);
  // },

  /*
   *  Recurring Payroll Deductions
   */

  recPayrollDeductionsFetch: () => {
    return client.get('/recurring-payroll-deductions').then(res => res.data);
  },

  recPayrollDeductionsCreate: ({donatableType, donatableId, amount, currencyCode, balanceType, anonymous=false, campaignId=null, note=null}) => {
    const body = {donatableType, donatableId, amount, currencyCode, balanceType, anonymous, campaignId, note};
    return client.post('/recurring-payroll-deductions', body).then(res => res.data);
  },

  recPayrollDeductionsCancel: (id) => {
    return client.delete(`/recurring-payroll-deductions/${id}`).then(res => res.data);
  },

  /*
   *  Account
   */

  accountUpdate: ({firstName, lastName, postalCode, email, avatarPath, private: isPrivate}) => {
    const body = {};
    if (firstName) body.firstName = firstName;
    if (lastName) body.lastName = lastName;
    if (postalCode) body.postalCode = postalCode;
    if (email) body.email = email;
    if (avatarPath) body.avatarPath = avatarPath;
    if (typeof isPrivate === 'boolean') body.private = isPrivate;
    return client.patch('/account', body).then(res => res.data);
  },

  accountPartialUpdate: ({currencyCode}) => {
    const body = {currencyCode};
    return client.patch('/account/partial', body).then(res => res.data);
  },

  accountSetNewPassword: (pw, pw2) => {
    const body = {pw, pw2};
    return client.patch('/account/password', body).then(res => res.data);
  },

  /*
   *  Gifts
   */

  giftsCreate: (params = {}) => {
    return client.post('/gifts', params).then(res => res.data);
  },

  giftsCreateDigital: (recipientEmail, amountInDollars, cardId, message) => {
    return Endpoints.giftsCreate({recipientEmail, amountInDollars, cardId, message});
  },

  giftsRedeem: (shortCode) => {
    return client.post(`/gifts/${shortCode}/redeem`).then(res => res.data);
  },

  /*
   *  Cards
   */

  cardsFetch: ({includeCompanySlug} = {}) => {
    const params = {includeCompanySlug};
    return client.get('/cards', {params}).then(res => res.data);
  },

  /*
   *  Pages
   */

  pagesGet: (slug) => {
    return client.get(`/pages/${slug}`).then(res => res.data);
  },

  /*
   *  Countries
   */

  countriesSearch: ({show} = {}) => {
    const params = {show};
    return client.get('/countries', {params}).then(res => res.data);
  },

  /*
   *  Actions
   */

  actionsFetch: ({limit=50, afterId, campaignId=null} = {}) => {
    const params = {limit, afterId, campaignId};
    return client.get('/actions', {params}).then(res => res.data);
  },

  actionsFetchMine: ({limit=50, afterId} = {}) => {
    const params = {limit, afterId, includeFunds: true};
    return client.get('/actions/mine', {params}).then(res => res.data);
  },

  /*
   *  Users
   */

  usersGet: (id) => {
    return client.get(`/users/${id}`).then(res => res.data);
  },

  usersClearAutoRedeemNotification: () => {
    return client.patch(`/users/clear-auto-redeem-notification`).then(res => res.data);
  },

  /*
   *  Nonprofit Dashboard
   */

  dashboardDisbursementsFetch: (nonprofitId) => {
    return client.get(`/dashboard/${nonprofitId}/disbursements`).then(res => res.data);
  },

  dashboardEntitiesFetch: (nonprofitId) => {
    return client.get(`/dashboard/${nonprofitId}/entities`).then(res => res.data);
  },

  /*
   *  Collections
   */

  collectionsFetch: ({limit=100, madness} = {}) => {
    const params = {limit, madness};
    return client.get('/collections', {params}).then(res => res.data);
  },

  collectionsGet: (idOrPath) => {
    return client.get(`/collections/${idOrPath}`).then(res => res.data);
  },

  /*
   *  Employees
   */

  employeesInviteGet: (token) => {
    return client.get(`/employees/invite/${token}`).then(res => res.data);
  },

  employeesInviteAccept: (token) => {
    return client.post(`/employees/invite/${token}/accept`).then(res => res.data);
  },

  employeesInviteResend: (email) => {
    const body = {email};
    return client.post(`/employees/invite/resend`, body).then(res => res.data);
  },

  /*
   * Public Forms
   */

  pfCorporateMatchingInquiry: ({email, companyName}) => {
    const body = {email, companyName};
    return client.post('/public-forms/corporate-matching-inquiry', body).then(res => res.data);
  },

  /*
   *  Companies
   */

  companiesGet: (id) => {
    return client.get(`/companies/${id}`).then(res => res.data);
  },


  companiesFetchEnterprise: ({search} = {}) => {
    const params = {search};
    return client.get(`/companies/enterprise`, {params}).then(res => res.data);
  },

  /**
   *  Campaigns
   */

  campaignsFetch: ({fundId, nonprofitId, isActive, includesDateStr}) => {
    const params = {fundId, nonprofitId, isActive, includesDateStr};
    return client.get('/campaigns', {params}).then(res => res.data);
  },

  campaignsGet: (id) => {
    return client.get(`/campaigns/${id}`).then(res => res.data);
  },

  campaignsFetchTemplates: () => {
    return client.get('/campaigns/templates').then(res => res.data);
  },

  /*
   *  Company Dashboards
   */

  companyDashboardsGet: (id) => {
    return client.get(`/company-dashboards/${id}`).then(res => res.data);
  },

  companyDashboardsGetData: (id, {startDate, endDate}) => {
    const params = {startDate, endDate};
    return client.get(`/company-dashboards/${id}/data`, {params}).then(res => res.data);
  },

  /*
   *  Geocode
   */

  geocodePlace: (placeId) => {
    const params = {placeId};
    return client.get(`/geocode/place/${placeId}`, {params}).then(res => res.data);
  },

  /*
   *  Vol Events
   */

  volEventsSearch: (params) => {
    return client.get('/vol-events', {params}).then(res => res.data);
  },

  volEventsGet: (id) => {
    return client.get(`/vol-events/${id}`).then(res => res.data);
  },

  volEventsRegister: (id, shiftIds) => {
    const body = {shiftIds};
    return client.put(`/vol-events/${id}/register`, body).then(res => res.data);    
  },

  volEventsUnregister: (id, participantId) => {
    return client.delete(`/vol-events/${id}/register/${participantId}`).then(res => res.data);    
  },

  /*
   *  Vol Event Participants
   */

  volEventParticipantsMine: () => {
    return client.get('/vol-event-participants/mine').then(res => res.data);
  },

  /*
   *  Vol Time Entries
   */

  volTimeEntriesFetch: (params) => {
    return client.get('/vol-time-entries', {params}).then(res => res.data);
  },

  volTimeEntriesDfdStatus: () => {
    return client.get('/vol-time-entries/dfd-status').then(res => res.data);
  },

  volTimeEntriesCreate: ({volEventId, nonprofitId, custom, minutes, dateStr} = {}) => {
    const body = {volEventId, nonprofitId, custom, minutes, dateStr};
    return client.post('/vol-time-entries', body).then(res => res.data);
  },

  /*
   *  Match Inquiries
   */

  matchInquiriesCreate: (nonprofitId) => {
    const body = {nonprofitId};
    return client.post('/match-inquiries', body).then(res => res.data);
  },

  /*
   *  Offline Donations
   */

  offlineDonationsCreate: ({receiptImgPath, amountInCents, nonprofitId, donatedAtStr, note, campaignId, dtdId}) => {
    const body = {receiptImgPath, amountInCents, nonprofitId, donatedAtStr, note, campaignId, dtdId};
    return client.post('/offline-donations', body).then(res => res.data);
  },

  offlineDonationsGet: (id) => {
    return client.get(`/offline-donations/${id}`).then(res => res.data);
  },

  offlineDonationsUpdate: (id, attrs) => {
    const body = attrs;
    return client.patch(`/offline-donations/${id}`, body).then(res => res.data);
  },

  /*
   *  SlackTokens
   */

  slackTokensGet: (id) => {
    return client.get(`/slack-tokens/${id}`).then(res => res.data);
  },

  /*
   *  SlackConnectNonces
   */

  slackConnectNoncesGet: (id) => {
    return client.get(`/slack-connect-nonces/${id}`).then(res => res.data);
  },

  slackConnectNoncesRedeem: (id) => {
    return client.patch(`/slack-connect-nonces/${id}/redeem`).then(res => res.data);
  },

  /*
   *  Donatables
   */

  donatablesPath: (id) => {
    return client.get(`/donatables/${id}/path`).then(res => res.data);
  },

  /*
   *  Groups
   */

  groupsFetch: () => {
    return client.get(`/groups`).then(res => res.data);
  },

  groupsGet: (id) => {
    return client.get(`/groups/${id}`).then(res => res.data);
  },

  groupsMembership: () => {
    return client.get(`/groups/membership`).then(res => res.data);
  },

  groupsJoin: (id) => {
    return client.post(`/groups/${id}/join`).then(res => res.data);
  },

  groupsLeave: (id) => {
    return client.post(`/groups/${id}/leave`).then(res => res.data);
  },

  groupsTypesFetch: () => {
    return client.get(`/groups/types`).then(res => res.data);
  },

  /*
   *  Brackets
   */

  bracketsGet: (id) => {
    return client.get(`/brackets/${id}`).then(res => res.data);
  },

  bracketsGetVoteRecord: (id) => {
    return client.get(`/brackets/${id}/vote-record`).then(res => res.data);
  },

  /*
   *  Bracket Games
   */

  bracketGamesGet: (id) => {
    return client.get(`/bracket-games/${id}`).then(res => res.data);
  },

  bracketGamesVote: (id, nonprofitId) => {
    const body = {nonprofitId};
    return client.put(`/bracket-games/${id}/vote`, body).then(res => res.data);
  },

  /*
   *  Urls
   */

  urlsMeta: (url) => {
    const params = {url};
    return client.get('/urls/meta', {params}).then(res => res.data);
  },

  urlsMetaCached: async (url) => {
    if (urlsMetaCache[url]) return urlsMetaCache[url];
    const meta = await Endpoints.urlsMeta(url);
    urlsMetaCache[url] = meta;
    return meta;
  },

  /*
   *  Static Pages
   */

  staticPagesGet: (page) => {
    return client.get(`/static-pages/${page}`).then(res => res.data);
  },

  /*
   *  Review Prompt
   */

  reviewPromptShouldShow: () => {
    return client.post(`/review-prompt/should-show`).then(res => res.data);
  },

  reviewPromptSetDismissedForever: (dismissedForever=true) => {
    const body = {dismissedForever: !!dismissedForever};
    return client.post(`/review-prompt/dismissed-forever`, body).then(res => res.data);
  },

  /*
   *  Cities
   */

  citiesGet: (id) => {
    return client.get(`/cities/${id}`).then(res => res.data);
  },

  /*
   *  Drive Donations
   */

  driveDonationsEntriesCreate: (attrs) => {
    const body = attrs;
    return client.post(`/drive-donations/entries`, body).then(res => res.data);
  },

  driveDonationsEntriesSearch: (params) => {
    return client.get(`/drive-donations/entries`, {params}).then(res => res.data);
  },

  driveDonationsEntriesUpdate: (entryId, attrs) => {
    const body = attrs;
    return client.patch(`/drive-donations/entries/${entryId}`, body).then(res => res.data);
  },

  driveDonationsEntriesDelete: (entryId) => {
    return client.delete(`/drive-donations/entries/${entryId}`).then(res => res.data);
  },

  /*
   *  Happenings
   */

  happeningsSearch: (params={}) => {
    return client.get(`/happenings`, {params}).then(res => res.data);
  },

  /*
   *  Utils
   */

  utilsTimestamp: ({timezone, dates, startDates, endDates}) => {
    const params = {timezone, dates, startDates, endDates};
    return client.get('/utils/timestamp', {params}).then(res => res.data);
  },

  /*
   *  FX Rates Sets
   */

  fxRatesSetsFetchLatest: () => {
    return client.get('/fx-rates-sets/latest').then(res => res.data);
  },

  /*
   *  Matches
   */

  matchesEstimate: ({donatableType, donatableId, campaignId}) => {
    const params = {donatableType, donatableId, campaignId};
    return client.get('/matches/estimate', {params}).then(res => res.data);
  },

  /*
   *  Percent
   */

  percentOrganizations: (params={}) => {
    return client.get('/percent/organizations', {params}).then(res => res.data);
  },

  percentRegistryNames: () => {
    return client.get('/percent/registry-names').then(res => res.data);
  },

  percentOrganizationsAdd: (percentId, {addToBuildQueue=false}={}) => {
    const body = {percentId, addToBuildQueue};
    return client.post('/percent/organizations', body).then(res => res.data);
  },

  percentOrganizationsRequest: (percentId) => {
    const body = {percentId};
    return client.post('/percent/organizations/request', body).then(res => res.data);
  },

  /*
   *  Every Cent
   */

  everyCentSearch: (fromDateStr, toDateStr, daf=null) => {
    const params = {fromDateStr, toDateStr, daf};
    return client.get('/every-cent', {params}).then(res => res.data);
  },

  /*
   *  Donation Track
   */

  donationTrack: (params={}) => {
    return client.get('/donation-track', {params}).then(res => res.data);
  },

  /*
   *  CattrSets
   */

  cattrSetsGet: () => {
    return client.get('/cattr-sets/employer').then(res => res.data);
  },

  cattrSetsSetResponse: (cattrs, attrs={}) => {
    const body = {...attrs, cattrs};
    return client.patch('/cattr-sets/employer/response', body).then(res => res.data);
  },

  /*
   *  PhoneNumbers
   */

  phoneNumbersValidation: (e164Input) => {
    const params = {e164Input};
    return client.get('/phone-numbers/validation', {params}).then(res => res.data);
  },

  /*
   *  Social
   */

  socialListingsSearch: (params) => {
    return client.get('/social/listings', {params}).then(res => res.data);
  },

  socialPostsCreate: (attrs) => {
    return client.post('/social/posts', attrs).then(res => res.data);
  },

  socialReactionsCreate: (postId, emojiChar) => {
    const body = {emojiChar};
    return client.post(`/social/posts/${postId}/reactions`, body).then(res => res.data);
  },

  socialReactionsDelete: (postId, emojiChar) => {
    const body = {emojiChar};
    return client.post(`/social/posts/${postId}/reactions/delete`, body).then(res => res.data);
  },

  /*
   *  Emojis
   */

  emojisAll: () => {
    return client.get('/emojis/all').then(res => res.data);
  },

};

export {
  client,
};

export default Endpoints;
