import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Icon          from 'app/components/common/icon';
import StandardInput from 'app/components/common/standard-input';
import Duck          from 'app/ducks/emojis';

const majorEmojiMap = {
  'Smileys & People': '😀',
  'Smileys & Emotion': '😀',
  'People & Body': '😀',
  'Animals & Nature': '🐢',
  'Food & Drink': '🍇',
  'Travel & Places': '🏯',
  'Activities': '🏀',
  'Objects': '🎩',
  'Symbols': '🚸',
  'Flags': '🏁',
};

const quickEmojis = [
  ['🙂', 'slightly smiling face'],
  ['👍', 'thumbs up'],
  ['✔', 'check mark'],
  ['❤', 'read heart'],
  ['👀', 'eyes'],
];

const noop = () => {};

class EmojiPicker extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      targetEmoji: null,
    };

    this.refBody = React.createRef();

    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onEnterEmoji = this.onEnterEmoji.bind(this);
    this.onLeaveEmoji = this.onLeaveEmoji.bind(this);
    this.onClickEmoji = this.onClickEmoji.bind(this);
  }

  componentDidMount() {
    this.props.load();
    this.props.setSearch(null);
    this.props.setMajor(null);
  }

  onChangeSearch(event) {
    const search = event.target.value;
    this.props.setSearch(search);
    const bodyEl = this.refBody.current;
    if (bodyEl) bodyEl.scrollTop = 0;
  }

  onClickMajor(majorName) {
    this.props.setMajor(majorName);
    const bodyEl = this.refBody.current;
    if (bodyEl) bodyEl.scrollTop = 0;
  }

  onClickEmoji(event) {
    const el = event.target;
    const buttonEl = el.closest('button');
    const emojiChar = buttonEl?.dataset?.emojiChar;
    if (!emojiChar) return;
    this.props.onSelect(emojiChar);
  }

  onEnterEmoji(targetEmoji) {
    this.setState(() => {
      return {targetEmoji};
    });
  }

  onLeaveEmoji() {
    this.setState(() => {
      return {targetEmoji: null};
    });
  }

  renderTabs() {
    const {majors, majorName} = this.props;
    if (!majors) return null;
    return (
      <div className="emopick-tabs">
        <button className={`emopick-tabs-tab emopick-btn ${majorName ? '' : 'active'}`} onClick={this.onClickMajor.bind(this, null)}>
          <span className={`emopick-btn-char emoji ${this.props.emojiClassName}`}>🔎</span>
        </button>
        {majors.map((major) => {
          const emojiChar = majorEmojiMap[major.name];
          return (
            <button key={major.name} className={`emopick-tabs-tab emopick-btn ${(majorName === major.name) ? 'active' : ''}`} onClick={this.onClickMajor.bind(this, major.name)}>
              <span className={`emopick-btn-char emoji ${this.props.emojiClassName}`}>{emojiChar}</span>
            </button>
          );
        })}
      </div>
    );
  }

  renderEmojis(emojis=[]) {
    return (
      <div className="emopick-list">
        {emojis.map((emoji) => {
          const [character, name] = emoji;
          return (
            <button key={character} onClick={this.onClickEmoji} data-emoji-char={character} className="emopick-list-emoji emopick-btn" onMouseEnter={this.onEnterEmoji.bind(this, emoji)} onMouseLeave={this.onLeaveEmoji}>
              <span className={`emopick-btn-char emoji ${this.props.emojiClassName}`}>{character}</span>
            </button>
          );
        })}
      </div>
    );
  }

  renderResults() {
    const {results, loadPending} = this.props;
    if (loadPending) return null;
    if (!results) return null;
    return (
      <div className="emopick-results">
        {this.renderEmojis(results)}
      </div>
    );
  }

  renderMajors() {
    const {filteredMajors: majors, results, loadPending} = this.props;
    if (loadPending) return null;
    if (results) return null;
    if (!majors) return null;

    return (
      <div className="emopick-majors">
        {majors.map((major) => {
          return (
            <React.Fragment key={major.name}>
              <div className="emopick-majors-name">{major.name}</div>
              {this.renderEmojis(major.emojis)}
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  render() {
    const {className, majorName, search, loadPending} = this.props;
    const {targetEmoji} = this.state;
    return (
      <div className={`emopick ${className}`}>
        <div className="emopick-head">
          {this.renderTabs()}
        </div>
        <div className="emopick-body" ref={this.refBody}>
          {!majorName && (
            <StandardInput className="emopick-search" name="search" label="Search" onChange={this.onChangeSearch} value={search} />
          )}
          {loadPending && <Icon.Loading className="emopick-loading" />}
          {this.renderResults()}
          {this.renderMajors()}
        </div>
        <div className="emopick-foot">
          <div className="emopick-quick">
            <div className="emopick-quick-label">Handy Reactions</div>
            {this.renderEmojis(quickEmojis)}
          </div>
          <div className="emopick-target">
            {!!targetEmoji && (<>
              <span className={`emopick-target-char emoji ${this.props.emojiClassName}`}>{targetEmoji[0]}</span>
              <span className="emopick-target-name">{targetEmoji[1]}</span>
            </>)}
          </div>
        </div>
      </div>
    );
  }

}

EmojiPicker.propTypes = {
  className: PropTypes.string,
  emojiClassName: PropTypes.string,
  onSelect: PropTypes.func,
};

EmojiPicker.defaultProps = {
  className: '',
  emojiClassName: '',
  onSelect: noop,
};

const stateToProps = (state) => ({
  search: Duck.Slx.search(state) || '',
  loadPending: Duck.Slx.loadPending(state),
  majors: Duck.Slx.majors(state),
  majorName: Duck.Slx.majorName(state),
  filteredMajors: Duck.Slx.filteredMajors(state),
  results: Duck.Slx.results(state),
});

const dispatchToProps = (dispatch) => ({
  load: () => dispatch(Duck.Ax.load()),
  setSearch: (search) => dispatch(Duck.Ax.setSearch(search)),
  setMajor: (majorName) => dispatch(Duck.Ax.setMajor(majorName)),
});

export default connect(stateToProps, dispatchToProps)(EmojiPicker);
