import { createSelector } from 'reselect';

import AuthSlx     from 'app/selectors/auth';
import EntitiesSlx from 'app/selectors/entities';

const selLoadPending = state => state.pageVolEvent.loadPending;
const selVolEventId = state => state.pageVolEvent.volEventId;

const selVolEventParticipants = createSelector(
  [selVolEventId, EntitiesSlx.volEvents, EntitiesSlx.volEventParticipants, EntitiesSlx.employees],
  (id, volEvents, participants, employees) => {
    const volEvent = volEvents[id];
    if (!volEvent) return [];
    return (volEvent.volEventParticipantIds || []).map((id) => {
      const participant = {...participants[id]};
      participant.employee = employees[participant.employeeId];
      return participant;
    }).filter(p => p);
  }
);

const selMyParticipants = createSelector(
  [selVolEventParticipants, AuthSlx.currentUser, EntitiesSlx.volEventShifts],
  (participants, currentUser, shifts) => {
    if (!currentUser) return [];
    return participants.filter((p) => {
        return (p.userId === currentUser.id)
          || (p.employeeId && p.employeeId === currentUser.employment?.employee?.id);
      })
      .map(p => {
        return {
          ...p,
          shift: p.volEventShiftId && shifts[p.volEventShiftId],
        };
      });
  }
);

const selVolEvent = createSelector(
  [
    selVolEventId,
    EntitiesSlx.volEvents,
    EntitiesSlx.volEventShifts,
    EntitiesSlx.companies,
    EntitiesSlx.employees,
    EntitiesSlx.groups,
    selVolEventParticipants,
  ],
  (id, volEvents, shifts, companies, employees, groups, participants) => {
    const volEvent = volEvents[id];
    if (!volEvent) return null;
    const ve = {
      ...volEvent,
      company: companies[volEvent.companyId],
      leadEmployee: employees[volEvent.leadEmployeeId],
      shifts: (volEvent.volEventShiftIds || []).map((shiftId) => {
        const shift = {...shifts[shiftId]};
        shift.employees = participants.filter(p => p.volEventShiftId === shiftId).map(p => p.employee);
        return shift;
      }),
      groups: (volEvent.groupIds || []).map(id => groups[id]).filter(g => g),
    };
    if (ve.isOngoing) {
      ve.employees = participants.map(p => p.employee).filter(e => e);
    }
    return ve;
  }
);

export default {
  loadPending: selLoadPending,
  volEvent: selVolEvent,
  myParticipants: selMyParticipants,
  // volEventParticipants: selVolEventParticipants,
};
