import _ from 'lodash';
import moment from 'moment';
import timm from 'timm';
import { createSelector } from 'reselect';

import AuthAx            from 'app/actions/auth';
import MillieApi         from 'app/apis/millie';
import history           from 'app/history';
import paths             from 'app/paths';
import reducerUtils      from 'app/reducers/utils';
import EntitiesSlx       from 'app/selectors/entities';
import RoutingSlx        from 'app/selectors/routing';



/*
 *  Actions
 */

const Types = {
  LISTINGS_SEARCH: 'SOCIAL_LISTINGS_SEARCH',
  POSTS_CREATE: 'SOCIAL_POSTS_CREATE',
  REACTIONS_CREATE: 'SOCIAL_REACTIONS_CREATE',
  REACTIONS_DELETE: 'SOCIAL_REACTIONS_DELETE',
};

const Ax = {

  listingsSearch: (params) => (dispatch, getState) => {
    const promise = MillieApi.socialListingsSearch(params);
    return dispatch({type: Types.LISTINGS_SEARCH, promise, _entities: ['socialPostListings', 'socialPosts', 'employees', 'users', 'socialReactionSets']});
  },

  postsCreate: (attrs) => (dispatch, getState) => {
    const promise = MillieApi.socialPostsCreate(attrs);
    return dispatch({type: Types.POSTS_CREATE, promise, _entities: ['socialPost', 'employee', 'user', 'socialReactionSet']});
  },

  reactionsCreate: (postId, emojiChar) => {
    const promise = MillieApi.socialReactionsCreate(postId, emojiChar);
    return {type: Types.REACTIONS_CREATE, promise, postId, emojiChar, _entities: ['socialReactionSet']};
  },

  reactionsDelete: (postId, emojiChar) => {
    const promise = MillieApi.socialReactionsDelete(postId, emojiChar);
    return {type: Types.REACTIONS_DELETE, promise, postId, emojiChar, _entities: ['socialReactionSet']};
  },

};



/*
 *  Reducer
 */

const initialState = {
  reactingKeys: {},
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.POSTS_CREATE}_PENDING`]: (state, action) => {
    return state;
  },
  [`${Types.POSTS_CREATE}_RESOLVED`]: (state, action) => {
    return state;
  },
  [`${Types.POSTS_CREATE}_REJECTED`]: (state, action) => {
    return state;
  },

  [`${Types.REACTIONS_CREATE}_PENDING`]: (state, action) => {
    return {...state,
      reactingKeys: timm.setIn(state.reactingKeys, [action.postId, action.emojiChar], true),
    };
  },
  [`${Types.REACTIONS_CREATE}_RESOLVED`]: (state, action) => {
    return {...state,
      reactingKeys: timm.setIn(state.reactingKeys, [action.postId, action.emojiChar], false),
    };
  },
  [`${Types.REACTIONS_CREATE}_REJECTED`]: (state, action) => {
    return {...state,
      reactingKeys: timm.setIn(state.reactingKeys, [action.postId, action.emojiChar], false),
    };
  },

  [`${Types.REACTIONS_DELETE}_PENDING`]: (state, action) => {
    return {...state,
      reactingKeys: timm.setIn(state.reactingKeys, [action.postId, action.emojiChar], true),
    };
  },
  [`${Types.REACTIONS_DELETE}_RESOLVED`]: (state, action) => {
    return {...state,
      reactingKeys: timm.setIn(state.reactingKeys, [action.postId, action.emojiChar], false),
    };
  },
  [`${Types.REACTIONS_DELETE}_REJECTED`]: (state, action) => {
    return {...state,
      reactingKeys: timm.setIn(state.reactingKeys, [action.postId, action.emojiChar], false),
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selReactingKeys = state => state.social.reactingKeys;

  const selListingSlx = (listingId) => createSelector(
    [EntitiesSlx.socialPostListings, EntitiesSlx.socialPosts, EntitiesSlx.employees, EntitiesSlx.users],
    (listingsMap, postsMap, employeesMap, usersMap) => {
      if (!listingsMap[listingId]) return null;
      const listing = {...listingsMap[listingId]};
      listing.post = {...postsMap[listing.socialPostId]};
      listing.post.employee = employeesMap[listing.post.employeeId];
      listing.post.user = usersMap[listing.post.employee?.userId];
      return listing;
    }
  );

  return {
    listingSlx: selListingSlx,
    reactingKeys: selReactingKeys,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
