import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import SocialDuck        from 'app/ducks/social';
import history           from 'app/history';
import paths             from 'app/paths';
import reducerUtils      from 'app/reducers/utils';
import EntitiesSlx       from 'app/selectors/entities';
import RoutingSlx        from 'app/selectors/routing';



/*
 *  Actions
 */

const Types = {
  LOAD: 'PAGE_FEED_LOAD',
};

const Ax = {

  load: ({query}) => (dispatch, getState) => {
    const key = `${Math.random()}`;
    const promise = Promise.all([
      dispatch(SocialDuck.Ax.listingsSearch({feedType: 'Company'})),
      dispatch(SocialDuck.Ax.listingsSearch({feedType: 'Campaign', feedId: '9bb7f035-506b-413b-9c81-e1c668fd32cb'})),
    ]);
    return dispatch({type: Types.LOAD, promise, key});
  },

};



/*
 *  Reducer
 */

const initialState = {
  listingIds1: null,
  listingIds2: null,
  searchPending: false,
  searchKey: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      searchPending: true,
      searchKey: action.key,
      listingIds1: null,
      listingIds2: null,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    if (action.key !== state.searchKey) return state;
    return {...state,
      searchPending: false,
      listingIds1: action.result[0].socialPostListings.map(l => l.id),
      listingIds2: action.result[1].socialPostListings.map(l => l.id),
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    if (action.key !== state.searchKey) return state;
    return {...state,
      searchPending: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selListingIds1   = (state) => state.pageSocialFeed.listingIds1;
  const selListingIds2   = (state) => state.pageSocialFeed.listingIds2;
  const selSearchPending = (state) => state.pageSocialFeed.searchPending;

  return {
    searchPending: selSearchPending,
    listingIds1: selListingIds1,
    listingIds2: selListingIds2,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
