import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import EmojiPicker from 'app/components/common/emoji-picker';
import Icon        from 'app/components/common/icon';
import Modal       from 'app/components/common/modal';
import SocialDuck  from 'app/ducks/social';
import EntitiesSlx from 'app/selectors/entities';

class SocialPostReactionSet extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showPicker: false,
    };

    this.onOpenPicker = this.onOpenPicker.bind(this);
    this.onClosePicker = this.onClosePicker.bind(this);
    this.onSelectEmoji = this.onSelectEmoji.bind(this);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickRemove = this.onClickRemove.bind(this);
  }

  onOpenPicker() {
    this.setState({showPicker: true});
  }
  onClosePicker() {
    this.setState({showPicker: false});
  }

  onClickAdd(event) {
    const el = event.target;
    const buttonEl = el.closest('button');
    const emojiChar = buttonEl?.dataset?.emojiChar;
    if (!emojiChar) return;
    this.props.add(emojiChar);
  }

  onClickRemove(event) {
    const el = event.target;
    const buttonEl = el.closest('button');
    const emojiChar = buttonEl?.dataset?.emojiChar;
    if (!emojiChar) return;
    this.props.remove(emojiChar);
  }

  onSelectEmoji(emojiChar) {
    this.setState({showPicker: false});
    this.props.add(emojiChar);
  }

  renderPicker() {
    if (!this.state.showPicker) return null;
    return (
      <Modal onClose={this.onClosePicker}>
        <EmojiPicker onSelect={this.onSelectEmoji} />
      </Modal>
    );
  }

  render() {
    const {reactionSet, reactingKeys} = this.props;
    if (!reactionSet) return null;
    const {reactions} = reactionSet;
    return (
      <div className="spost-react">
        <div className="spost-react-emojis">
          {reactions.slice(0, 4).map((reaction) => {
            const isReacting = (reactingKeys || {})[reaction.emojiChar] || false;
            const activeClass = reaction.includesMe ? 'active' : '';
            const onClick = reaction.includesMe ? this.onClickRemove : this.onClickAdd;
            const disabled = isReacting;
            return (
              <button onClick={onClick} disabled={disabled} className={`spost-react-emoji ${activeClass}`} key={reaction.emojiChar} data-emoji-char={reaction.emojiChar}>
                <span className="spost-react-emoji-char emoji">{reaction.emojiChar}</span>
                <span className="spost-react-emoji-count">{reaction.count}</span>
              </button>
            );
          })}
        </div>
        <button onClick={this.onOpenPicker} className="spost-react-emoji add">
          <Icon.AddCircle className="spost-react-emoji-icon" />
        </button>
        {this.renderPicker()}
      </div>
    );
  }

}

SocialPostReactionSet.propTypes = {
  id: PropTypes.string.isRequired,
};

const stateToProps = (state, ownProps) => ({
  reactionSet: EntitiesSlx.socialReactionSets(state)[ownProps.id],
  reactingKeys: SocialDuck.Slx.reactingKeys(state)[ownProps.id],
});

const dispatchToProps = (dispatch, ownProps) => ({
  add: (emojiChar) => dispatch(SocialDuck.Ax.reactionsCreate(ownProps.id, emojiChar)),
  remove: (emojiChar) => dispatch(SocialDuck.Ax.reactionsDelete(ownProps.id, emojiChar)),
});

export default connect(stateToProps, dispatchToProps)(SocialPostReactionSet);
