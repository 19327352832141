import React from 'react';
import { connect } from 'react-redux';

import ClientTimestamp               from 'app/components/common/client-timestamp';
import Meta               from 'app/components/common/meta';
import MainLayout         from 'app/components/layout/main-layout';
import Masonry            from 'app/components/social/masonry';
import PostCard           from 'app/components/social/post-card';
import ModalPostDuck      from 'app/ducks/modal-social-post';
import Duck               from 'app/ducks/page-social-feed';

class PageFeed extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickNewPost = this.onClickNewPost.bind(this);
  }

  onClickNewPost() {
    this.props.createPost();
  }

  render() {
    return (
      <MainLayout className="page-feed" dnd>
        <Meta title="Feed | Millie" />
        <div className="widther">
          <h1>Feed <ClientTimestamp timestamp={1733576526} format="dddd, MMM Do, YYYY, H:mmA" /></h1>
          {/* <button className="btn" onClick={this.onClickNewPost}>New Post</button> */}
          <h2>Company</h2>
          <Masonry listingIds={this.props.listingIds1} feedType="Company" feedId="3b4c1dab-f9bc-4b88-813d-b8c95c755ea4" />
          <h2>Campaign</h2>
          <Masonry listingIds={this.props.listingIds2} feedType="Campaign" feedId="9bb7f035-506b-413b-9c81-e1c668fd32cb" />
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  searchPending: Duck.Slx.searchPending(state),
  listingIds1: Duck.Slx.listingIds1(state),
  listingIds2: Duck.Slx.listingIds2(state),
});

const dispatchToProps = (dispatch) => ({
  createPost: () => dispatch(ModalPostDuck.Ax.open()),
});

export default connect(stateToProps, dispatchToProps)(PageFeed);


