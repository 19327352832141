import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import utils  from 'app/helpers/utils';
import AppSlx from 'app/selectors/app';

class ClientTimestamp extends React.PureComponent {

  render() {
    const {className, timestamp, format, isServer} = this.props;
    if (isServer) return null;
    const isoStr = utils.timestampToLocalIsoStr(timestamp, 'America/Denver');
    const formatStr = moment.utc(isoStr).format(format);
    return (
      <span className={className}>{formatStr}</span>
    );
  }

}

ClientTimestamp.propTypes = {
  timestamp: PropTypes.number.isRequired,
  format: PropTypes.string,
};

ClientTimestamp.defaultProps = {
  format: 'MMM D, YYYY',
};

const stateToProps = (state) => ({
  isServer: AppSlx.isServer(state),
});

export default connect(stateToProps)(ClientTimestamp);
